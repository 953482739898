import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"
import { getImage, getSrc, getSrcSet } from "gatsby-plugin-image"

export const HeroGridElement = styled.div`
  display: grid;

  grid-template-rows: auto;
  grid-template-columns: auto auto;

  grid-template-areas: "heroLeft heroRight";

  background-color: ${(props) => props.mobileColor};

  @media screen and (min-width: 720px) {
    grid-template-columns: 60% 40%;
    background-position: center;
    background-color: unset;
  }

  @media screen and (min-width: 1020px) {
    ${"" /* grid-template-columns: 40% 60%; */}
    ${"" /* grid-template-columns: 50vw 50vw; */}
    ${"" /* grid-template-columns: 50% 50%; */}
    grid-template-columns: 40% 60%;
    background-position: center;
    background-color: unset;
  }
`

export const HeroImageElement = styled(BackgroundImage)`
  ${"" /* background-image:  ${(props) => props.backgroundimage}; */}
  background-size: cover;
  background-repeat: no-repeat;
  background-position: ${(props) => props.bgX} ${(props) => props.bgY} !important;
  ${"" /* background-position: 60% 0% !important; */}
  ${
    "" /* background-color: linear-gradient(90deg, rgba(0,0,0,1) 10%, rgba(255,255,255,0.03) 100%) !important;
  background: linear-gradient(90deg, rgba(0,0,0,1) 10%, rgba(255,255,255,0.03) 100%) !important;  */
  }
  background: rgba(0,0,0,0.8);

  opacity: 0.9 !important;

  background-size: cover;
  ${"" /* background-color: ${(props) => props.mobileColor}; */}

  @media screen and (max-width: 400px) {
    fluid: unset;
    display: hidden;
  }

  ${
    "" /* background: linear-gradient(
    90deg,
    rgba(3,113,227,0.9) 17.75%,
    rgba(238,169,64,0.61) 99.89%);
  ); */
  }
`

// export const HeroImageElement = styled(BackgroundImage)`
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-position: ${(props) => props.bgX} ${(props) => props.bgY} !important;
//   ${"" /* background-position: 60% 0% !important; */}
//   ${
//     "" /* background-color: linear-gradient(90deg, rgba(0,0,0,1) 10%, rgba(255,255,255,0.03) 100%) !important;
//   background: linear-gradient(90deg, rgba(0,0,0,1) 10%, rgba(255,255,255,0.03) 100%) !important;  */
//   }
//   background: rgba(0,0,0,0.8);

//   opacity: 0.9 !important;

//   background-size: cover;

//   )

//   ${
//     "" /* background: linear-gradient(
//     90deg,
//     rgba(3,113,227,0.9) 17.75%,
//     rgba(238,169,64,0.61) 99.89%);
//   ); */
//   }
// `

export const HeroSectionElement = styled.div`
  grid-area: heroLeft;
  display: flex;
  flex-direction: column;

  ${"" /* padding-left: 30px; */}
  padding-bottom: 0px;
  padding-top: 0px;
  padding-right: 0px;
  ${"" /* margin-right: 50px; */}
  justify-content: center;
`

export const HeroExtraElement = styled.div`
  grid-area: heroRight;
  display: flex;
  flex-direction: column;

  padding-left: 30px;
  padding-bottom: 0px;
  padding-top: 0px;
  padding-right: 0px;
  margin-right: 50px;
  justify-content: center;

  font-family: Roboto;
  font-size: 0.8rem;
`

export const HeroHeadlineElement = styled.div`
  ${
    "" /* * {
    border-width: 2px;
    border-style: solid;
    border-color: red;
  } */
  }
  h1 {
    ${
      "" /* font-family: Roboto;
    font-weight: 700;
    line-height: 3.3rem;
    font-size: 2.5rem;
    color: #333333; */
    }

    font-family: ${(props) => props.theme.fonts.header1};
    font-weight: ${(props) => props.theme.fonts.weight700};
    font-size: ${(props) => props.theme.spacings.xxMedium};
    line-height: ${(props) => props.theme.spacings.large};
    color: ${(props) => props.theme.colors.black};
  }

  h2 {
    font-family: ${(props) => props.theme.fonts.header2};
    font-weight: ${(props) => props.theme.fonts.weight400};
    font-size: ${(props) => props.theme.spacings.xMedium};
    line-height: ${(props) => props.theme.spacings.large};
    color: ${(props) => props.theme.colors.black};
  }
`

export const HeroSubHeadElement = styled.div`
  p {
    font-family: "PT Sans";
    font-weight: 300;
    line-height: 25px;
    line-height: 1.8rem;
    font-size: 1.4rem;
    color: #333333;

    font-family: ${(props) => props.theme.fonts.main};
    font-weight: ${(props) => props.theme.fonts.weight400};
    font-size: ${(props) => props.theme.spacings.small};
    line-height: ${(props) => props.theme.spacings.medium};
    color: ${(props) => props.theme.colors.black};
  }
`

export const HeroSubHeadLandingElement = styled.div`
  p {
    font-family: ${(props) => props.theme.fonts.main};
    font-weight: ${(props) => props.theme.fonts.weight400};
    font-size: ${(props) => props.theme.spacings.small};
    line-height: ${(props) => props.theme.spacings.medium};
    color: ${(props) => props.theme.colors.black};

    @media screen and (min-width: 760px) {
      font-size: ${(props) => props.theme.spacings.medium};
      line-height: ${(props) => props.theme.spacings.xMedium};
    }

    @media screen and (min-width: 1020px) {
      font-size: ${(props) => props.theme.spacings.xMedium};
      line-height: ${(props) => props.theme.spacings.xxMedium};
    }
  }
`

export const HeroHeadlineLandingElement = styled.div`
  h1 {
    ${
      "" /* font-family: Roboto;
    font-weight: 700;
    line-height: 3.3rem;
    font-size: 2.5rem;
    color: #333333; */
    }

    font-family: ${(props) => props.theme.fonts.header1};
    font-weight: ${(props) => props.theme.fonts.weight700};
    font-size: ${(props) => props.theme.spacings.xxMedium};
    line-height: ${(props) => props.theme.spacings.large};
    color: ${(props) => props.theme.colors.black};
  }

  h2 {
    font-family: ${(props) => props.theme.fonts.header2};
    font-weight: ${(props) => props.theme.fonts.weight400};
    font-size: ${(props) => props.theme.spacings.xMedium};
    line-height: ${(props) => props.theme.spacings.large};
    color: ${(props) => props.theme.colors.black};
  }

  @media screen and (min-width: 760px) {
    h1 {
      font-size: ${(props) => props.theme.spacings.xLarge};
      line-height: ${(props) => props.theme.spacings.xLarge};
    }

    h2 {
      font-size: ${(props) => props.theme.spacings.xMedium};
      line-height: ${(props) => props.theme.spacings.large};
    }
  }

  @media screen and (min-width: 1020px) {
    h1 {
      font-size: ${(props) => props.theme.spacings.xxLarge};
      line-height: ${(props) => props.theme.spacings.xxLarge};
    }

    h2 {
      font-size: ${(props) => props.theme.spacings.xMedium};
      line-height: ${(props) => props.theme.spacings.large};
    }
  }
`

// export const HeroImageElement = styled.div`
//   ${"" /* border: 2px solid orange; */}
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `

export const HeroButtonElement = styled.button`
  border-radius: 4px;
  color: white;
  background-color: red;
  margin-top: 10px;
  width: 50%;
  padding: 10px;
  border: 1px solid red;
  transition-duration: 0.4s;
  box-shadow: 5px 5px 16px 3px grey;

  :hover {
    background-color: white;
    color: red;
  }
`
