import React from "react"

import {
  HeroGridElement,
  HeroSectionElement,
  HeroHeadlineElement,
  HeroSubHeadElement,
  HeroImageElement,
  HeroExtraElement,
  HeroButtonElement,
  HeroHeadlineLandingElement,
  HeroSubHeadLandingElement,
  // HeroBottomRowElement,
} from "../elements/HeroElements"

export const HeroHeadline = (props) => {
  return <HeroHeadlineElement {...props}>{props.children}</HeroHeadlineElement>
}

export const HeroSubHead = (props) => {
  return <HeroSubHeadElement {...props}>{props.children}</HeroSubHeadElement>
}

export const HeroImage = (props) => {
  return <HeroImageElement {...props}>{props.children}</HeroImageElement>
}

export const HeroExtra = (props) => {
  return <HeroExtraElement {...props}>{props.children}</HeroExtraElement>
}

export const HeroButton = (props) => {
  return <HeroButtonElement {...props}>{props.children}</HeroButtonElement>
}

export const HeroSection = (props) => {
  return <HeroSectionElement {...props}>{props.children}</HeroSectionElement>
}

// export const HeroBottomRow = (props) => {
//   return <HeroBottomRowElement {...props}>{props.children}</HeroBottomRowElement>
// }

// export const HeroGrid = (props) => {
//   return (
//     <HeroImageElement fluid={props.picUrl} bgX={props.bgX} bgY={props.bgY}>
//       <HeroGridElement {...props}>{props.children}</HeroGridElement>
//     </HeroImageElement>
//   )
// }

export const HeroGrid = (props) => {
  return (
    <HeroImageElement fluid={props.picUrl} bgX={props.bgX} bgY={props.bgY}>
      <HeroGridElement {...props}>{props.children}</HeroGridElement>
    </HeroImageElement>
  )
}

export const HeroHeadlineLanding = (props) => {
  return <HeroHeadlineLandingElement {...props}>{props.children}</HeroHeadlineLandingElement>
}

export const HeroSubHeadLanding = (props) => {
  return <HeroSubHeadLandingElement {...props}>{props.children}</HeroSubHeadLandingElement>
}
